import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted,toRaw,watch,computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();

	    const id = route.query.id;

		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			newsDetail:{},
			team1:[],
			team2:[],
			pagination:{},
			imgcount: 0,
			id: 0,
			imgH: 533,
			type: 0,
			isMoreLoading: false,
			page: 1,
			nomore: true,
			imgList: [],
			pagination: 0,
			currentPage: 1,
			pageSize: 0,
		});
		// const imageHeight = ref(533);
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		data.id = id;
		const store = useStore();

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}
		const activeStyle = (item,w) => {		

			// return new Promise((resolve,reject)=>{
				let object = {};
				let height = item.ratio_h*w/item.ratio_w+'px';
				return height;
			// })
						
		}

		

		const orderImgs = (imgs) => {
			// let arr = [{},{},{},{}];
			if(data.imgcount == 4 && data.type == 1) {
				Loop1:
				for(let i=0;i<imgs.length;i++){
					Loop2:
					for(let j=1;j<imgs.length;j++){

						if((i==0||i==3) && (j==1||j==2)){

							if(imgs[i].direction == 3){
								if(imgs[j].direction == 3){
									continue;
								}else if(imgs[j].direction == 2){
									let temp = imgs[i];
									imgs[i] = imgs[j];
									imgs[j] = temp;
									continue Loop1;
								}
							}

						}

					}
				}
			}else if(data.imgcount == 3 && data.type == 1) {
				Loop1:
				for(let i=0;i<imgs.length;i++){
					Loop2:
					for(let j=1;j<imgs.length;j++){

						if((i==0) && (j==1||j==2)){

							if(imgs[i].direction == 3){
								let h = imgs[i].ratio_h * 1184 * 0.5 / imgs[i].ratio_w;
								data.imgH = h;
								// console.log(data,"imgH");
								break Loop1;
							}else if(imgs[i].direction == 2){
								if(imgs[j].direction == 3){
									let temp = imgs[i];
									imgs[i] = imgs[j];
									imgs[j] = temp;
									let h = imgs[i].ratio_h * 1184 * 0.5 / imgs[i].ratio_w;
									data.imgH = h;
									break Loop1;
								}else if(imgs[j].direction == 2){
									continue Loop2;
								}
								
							}
								

						}

					}
				}
			}
			
			return imgs;
		}

		
        const getNewsDetail= (id=0,page=1)=>{
        	return new Promise((resolve,reject) => {
        		store.dispatch("common/getNewsDetail",{
        			id:id,
        			page:page,
        		}).then((res) => {        		
		        	data.newsDetail = JSON.parse(JSON.stringify(res));
		        	console.log(res,"newsdetail")
		        	
		        	if(res.image.data.length > 0){
			        	if(page == 1){
			        		data.imgcount = res.image.data.length;
			        		for(let i=0;i<res.image.data.length;i++){
				        		if(res.image.data.length==3){
				        			if(i<3){
				        				if( parseInt(res.image.data[i].ratio_w) < parseInt(res.image.data[i].ratio_h) ){
				        					data.newsDetail.image.data[i].direction = 3;
									    	data.type = 1;
									    	
									    }else if( parseInt(res.image.data[i].ratio_w) > parseInt(res.image.data[i].ratio_h) ){
									    	data.newsDetail.image.data[i].direction = 2;
									    }
				        				data.team1.push(data.newsDetail.image.data[i])
				        				data.team1 = orderImgs(data.team1)
				        				
					        		}
					        		
				        		}
				        		if(res.image.data.length==6){

				        			if(i<3){
					        			data.team1.push(data.newsDetail.image.data[i])
					        		}
					        		if(i>=3){
					        			data.team2.push(data.newsDetail.image.data[i])
					        		}

				        		}
				        		if(res.image.data.length==5){
				        			if(i<2){
					        			data.team1.push(data.newsDetail.image.data[i])
					        		}
					        		if(i>=2){
					        			data.team2.push(data.newsDetail.image.data[i])
					        		}
				        		}
				        		if(res.image.data.length==4 || res.image.data.length==2){
				        			if(i<4){
				        				if( parseInt(res.image.data[i].ratio_w) < parseInt(res.image.data[i].ratio_h) ){
				        					data.newsDetail.image.data[i].direction = 3;
									    	data.type = 1;
									    	
									    }else if( parseInt(res.image.data[i].ratio_w) > parseInt(res.image.data[i].ratio_h) ){
									    	data.newsDetail.image.data[i].direction = 2;
									    }
									    data.team1.push(data.newsDetail.image.data[i])
				        				data.team1 = orderImgs(data.team1)
				        				
					        		}

				        		}
				        		// if(res.image.data.length > 6 && res.image.data.length <= 10 ){
				        		// 	data.imgList = res.image.data;
				        		// }
				        		if(res.image.data.length > 6 ){
				        			data.imgList = res.image.data;
				        		}
				        	}
				        	// console.log(data.team1,"data.team1")
			        	}else{
			        		data.imgList = data.imgList.concat(res.image.data);
			        	}

			        	if(res.image.data.length < res.image.pagination.pageSize){
							data.nomore = true;
						}else{
							data.nomore = false;
						}

			        }else{
			        	data.nomore = true;
			        }

		        	data.isMoreLoading = false;
		        	if(!data.nomore){
			        	data.page = page + 1;
			        }
			        console.log(data.imgList,"imgList");
		        	resolve();

		        }).catch(err=>{
		        	data.isMoreLoading = false;
		        	data.nomore = false;
		        })
        	})
        }
        
        
  //       const newteam1 = [];
  //       const newteam2 = [];
  //       watch(() => [data.team1,data.team2],([newTeam1,newTeam2],[oldTeam1,oldTeam2]) => {
		//     console.log(newval,"newval")
		//     newteam1.value = newTeam1;
		//     newteam2.value = newTeam2;
		    
		// },{
		//     // immediate: true
		// })
  
     	
	    onMounted(async()=>{ 
	    	data.page = 1;
	    	await getNewsDetail(data.id,data.page);   
	    	
      		await nextTick(()=>{
      			bus.emit('showFooter',true);
      		});

        	setTimeout(() => {  
        		// document.getElementById("team1").style.height = data.imageHeight+'px'; 
		      	data.isLoading = false;
		      	viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
			scroll,
			getNewsDetail,
			orderImgs,

		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	
	methods: {
		init() {
			
			// let timer = setTimeout(this.getNewsDetail(this.id),500);
			
			// setTimeout(()=>{
			// 	this.getNewsDetail(this.id);
			// 	console.log(this.imageHeight,"this.imageHeight");
			// },200)
			
		},

		loadMore() {
			let that = this;
			this.isMoreLoading = true;
			console.log(this.page,"this.page");
			this.getNewsDetail(this.id, this.page);
		},

		activeStyle1(data) {
			let object = {width:0};
			object.width = data.ratio_w*230/data.ratio_h+'px';
			// object.height = '230px';
			return object;
		},
		
		imgStyle(img, isOuter=0) {
			let obj = {};
			if(document.documentElement.clientWidth >= 768){
				if(this.imgcount == 4) {
					if(this.type == 1) { //有竖图
						if(img.direction == 3){
							obj.width = '33.333%';//'31.1%';
							obj.height = '500px';
						}else if(img.direction == 2){
							obj.width = '66.666%';//'68.9%';
							obj.height = '500px';
						}
					} else {
						// if(img.direction == 2){
							obj.width = '50%';
							obj.height = '402px';//'329.33';
						// }
					}
				} else if(this.imgcount == 3) {
					if(this.type == 1) {
						if(img.direction == 3) {
							let h = obj.ratio_h * 1184 * 0.5 / obj.ratio_w + 'px';
							obj.width = '50%';
							obj.height = h;
							
						}else if(img.direction == 2){
							obj.width = '50%';
							obj.height = '50%';
						}
						
					} else {
						// if(img.direction == 3){
						// 	obj.width = '33.333%';
						// 	obj.height = 'auto';
						// }else if(img.direction == 2){
						// 	obj.width = '66.666%';
						// }
					}
				} else if (this.imgcount == 2) {
					obj.width = '50%';
					obj.height = '402px';
				}
			}else{
				obj.width = '100%';
				obj.height = '50%';
			}
			
			return obj;
		},
		
		activestl(){
			let obj = {};
			obj.height = this.imageHeight;
			return obj;
		},
		
		toPicDetail(id){
			this.$router.push({ name:'topic_pic_detail', query:{ id: id } });
		},
	},
})