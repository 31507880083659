// import Search from '@/components/Search.vue'
import { nextTick, onMounted, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Loading from '@/components/common/Loading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import axios from 'axios';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default {
	name: 'photo_grapher',
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBarPic,
	    Footer,
	    PageLoading,
	    SideComponent,
	    Loading,
	},
	setup(){	    
	    // 写法一 用了reactive，就不需要写ref
	    let {ctx, proxy} = getCurrentInstance();
      	const router = useRouter();
      	const route = useRoute();
      	const actid = route.query.actid;
      	let type = route.query.type?route.query.type:0;
	    let id = route.query.id?route.query.id:0;
      	provide('logoColor', 'white');
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[
      			
      		],
      		
      		picList: [],
      		videoList: [],
      		pagination: {},
      		currentTag: 0,
      		catid: 0,
      		page: 1,
      		nomore: true,
      		keyword: '',
      		activeName:'first',
      		picFiles: '',
			isPicLoading: false,
			isVideoLoading: false,
			isImgSingleLoading: false,
			isVideoSingleLoading: false,
			imgInfo: [],
			videoInfo: [],
			filesInfo: [],
			formSize: 'default',
			pagination:{},
			page:1,
			keywords:'',
			allCats:[],
			showSubject: false,
			subtype: 1,
			nomore:true,
			showImgUpload:true,
			showPicForm: false,
			showVideoUpload:true,
			showVideoForm: false,
			hasImagePermissions: false,
			hasVideoPermissions: false,
			disabled: false,    
			type: 1, 
			actList: [], 
			actid: 0,	
			search:'',	
			id:0,
			isAgreementPic: '',
			isAgreementVideo: '',
			protocol: '',
			showDialog: false,
			picTags:[],
			pic_keyword:'',
			videoTags:[],
			video_keyword:'',
			pic_disabled:false,
			video_disabled:false,
			folder_list:[],
			video_folder_list:[],
			folder_pagination:{},
			folder_page:1,
			video_folder_page:1,
			folder_keywords:'',
			folder_nomore: true,
			video_folder_nomore: true,
			showFolder: false,
			coverType:[{
				id: 1,
				name: proxy.$t('active.cover_type_1'),
				tip: proxy.$t('active.cover_type_1_tip'),
			},{
				id: 2,
				name: proxy.$t('active.cover_type_2'),
				tip: proxy.$t('active.cover_type_2_tip'),
			}],
			checkedCoverType: 1,
			folder_disabled: false,
			folderThumbLoading:false,
			folderFormLoading:false,
			picCate: [],
			videoCate: [],
	    });
	    data.actid = actid;
	    // console.log(data.actid,"catid");
	    if(id>0 && type){
			data.type = type;
			data.id = id;
			if(type==1){
				data.activeName = 'first';
			}else if(type==2){
				data.activeName = 'second';
			}
		}
        const store = useStore();

        const getActivityList = (search='',page=1) =>{

	        store.dispatch("user/ActivityList",{
	        	page: page,
	        	search: search,
	        }).then((res) => {
	        	console.log(res,"res");
	        	let list = res.data;
	        	
	            if(list.length > 0){
	            	if(page == 1){
	            		data.actList = list;
	            	}else{
	            		data.actList = data.actList.concat(list);
	            	}
					
					if(list.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}

				}else{
					data.nomore = true;
				}        
	        	// data.pagination = res.pagination;
	        	data.page += 1;
	        	// data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        })

        }
        const getActivityDetail = (actid,type) => {
        	store.dispatch("user/ActivityDetail",{
	        	id: actid
	        }).then((res) => {
	        	console.log(res.cates,"detail");
	        	// if(type==1){
					data.picCate = res.cates;
				// }else if(type==2){
					data.videoCate = res.cates;
				// }
	        	bus.emit('showFooter',true);
	        })
        }
        const FolderList = (type=1,status=-1,page=1) =>{
        	
	        store.dispatch("user/FolderList",{
	        	page: page,
	        	type: type,
	        	status: status,
	        }).then((res) => {
	        	console.log(res,"FolderList");
	        	let list = res.data;
	        	console.log(type,"type");
	            if(type==1){
	            	if(page == 1){
	            		data.folder_list = list;
	            	}else{
	            		data.folder_list = data.folder_list.concat(list);
	            	}
					
					if(list.length < res.pagination.pageSize){
						data.folder_nomore = true;
					}else{
						data.folder_nomore = false;
					}

					      
		        	// data.pagination = res.pagination;
		        	data.folder_page += 1;
		        	// data.isPicLoading = false;
	            }else if(type==2){
	            	if(page == 1){
	            		data.video_folder_list = list;
	            	}else{
	            		data.video_folder_list = data.video_folder_list.concat(list);
	            	}
					
					if(list.length < res.pagination.pageSize){
						data.video_folder_nomore = true;
					}else{
						data.video_folder_nomore = false;
					}

					      
		        	// data.pagination = res.pagination;
		        	data.video_folder_page += 1;
		        	// data.isPicLoading = false;
	            }
            	
	        })

        }

        const picOneRef = ref()
		const picOneForm = reactive({			
  			description:'',
  			keywords: [],
  			thumb: '',	
  			actid: data.actid,
  			folder_id:'',
  			author:'',
  			create_date:'',
  			cate:'',
		})

		const videoOneRef = ref()
		const videoOneForm = reactive({				
  			description:'',
  			keywords: [],
  			actid: data.actid,
  			thumb: '',	
  			url:'',
  			folder_id:'',
  			author:'',
  			create_date:'',
  			cate:'',

		})
        
        const ruleRef = ref()
		const ruleForm = reactive({
			tableData:[{
				title:'',			
	  			description:'',
	  			keywords: [],
	  			thumb: '',	
	  			actid: data.actid,
	  			url:'',
	  			pic_keyword:'',
	  			folder_id:'',
	  			author:'',
	  			create_date:'',
	  			cate:'',
	  			hasSimilar: false,
			}]
		})

		const _rules = reactive({
			title: [
				{ required: true, message: proxy.$t('tip6'), trigger: 'blur' },
				
			],
			// actid: [
			// 	{ required: true, message: proxy.$t('activity.selectAct'), trigger: 'blur' },
			// ],
	
		})

		const submitForm = (formEl) => {			
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.pic_disabled = true;
			if(data.isAgreementPic != '1'){
				ElMessage({
				    message: proxy.$t('apply_common.read'),
				    grouping: true,
				    type: 'warning',
				})
				data.pic_disabled = false;
				return;
			}
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
				    let param = {};
				    let arr = [];
				    let has = false;
				    ruleForm.tableData.forEach((item,index)=>{
				    	if(item.hasSimilar){
				    		has = true;
				    	}
				    	let obj = {
				    		title: item.title,
				    		description: item.description,
				    		keywords: item.keywords,//.split(','),
				  			thumb: item.thumb,
				  			actid: item.actid,
				  			url: item.url?item.url:'',
				  			folder_id: item.folder_id,
				  			author: item.author,
				  			create_date: item.create_date,
				  			cate: item.cate,
				    	}
				    	arr.push(obj);
				    })
				    param.type = data.type;
				    param.data = arr;
				    console.log(param,"pic param");
				  //   if(has){
				  //   	ElMessage({
						//     message: "请勿重复上传图片作品",
						//     grouping: true,
						//     type: 'error',
						// })
						// data.pic_disabled = false;
				  //   }else{
					    if(id>0 && type==1){
					    	store.dispatch("user/getCollectUpdate",{id:id,body:arr[0]}).then((res)=>{
						      	console.log("getCollectUpdate",res);
						      	ElMessage({
								    message: proxy.$t('apply_common.upload_success'),
								    grouping: true,
								    type: 'success',
								})
						      	router.push({name:'collect_work',query:{type:data.type}})
						    }).catch(err => {
					            // console.error(err)
					            ElMessage({
								    message: err.message,
								    grouping: true,
								    type: 'error',
								})
								data.pic_disabled = false;
					        })
					    }else{
					    	console.log(param,"pic param-2");
					    	store.dispatch("user/PostCollect",param).then((res)=>{
						      	console.log("PostCollect",res);
						      	ElMessage({
								    message: proxy.$t('apply_common.upload_success'),
								    grouping: true,
								    type: 'success',
								})

						      	router.push({name:'collect_work',query:{type:data.type}})
								
						    }).catch(err => {
					            // console.error(err)
					            ElMessage({
								    message: err.message,
								    grouping: true,
								    type: 'error',
								})
								data.pic_disabled = false;
					        })
					    }
				    // }
			    } else {
			      console.log('error submit!')
			      data.pic_disabled = false;
			      return false
			    }
			})
		}


		const ruleVideoRef = ref()
		const ruleVideoForm = reactive({
			tableData:[{
				title:'',			
	  			description:'',
	  			keywords: [],
	  			actid: data.actid,
	  			thumb: '',	
	  			url:'',
	  			video_keyword:'',
	  			folder_id:'',
	  			author:'',
	  			create_date:'',
	  			cate:'',
			}]
		})

		const video_rules = reactive({
			title: [
				{ required: true, message: proxy.$t('tip31'), trigger: 'blur' },
				
			],
			// actid: [
			// 	{ required: true, message: proxy.$t('activity.selectAct'), trigger: 'blur' },
			// ],
		})

		const submitVideoForm = (formEl) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.video_disabled = true;
			if(data.isAgreementVideo != '1'){
				ElMessage({
				    message: proxy.$t('apply_common.read'),
				    grouping: true,
				    type: 'warning',
				})
				data.video_disabled = false;
				return;
			}
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
				    let param = {};
				    let arr = [];
				    ruleVideoForm.tableData.forEach((item,index)=>{
				    	
				    	let obj = {
				    		title: item.title,
				    		description: item.description,
				    		keywords: item.keywords,//.split(','),
				  			thumb: item.thumb,
				  			actid: item.actid,
				  			url: item.url?item.url:'',
				  			folder_id: item.folder_id,
				  			author: item.author,
				  			create_date: item.create_date,
				  			cate: item.cate,
				    	}
				    	arr.push(obj);
				    })
				    param.type = data.type;
				    param.data = arr;
				    console.log(param,"video param");
				    if(id>0 && type==2){
				    	store.dispatch("user/getCollectUpdate",{id:id,body:arr[0]}).then((res)=>{
					      	// console.log("getCollectUpdate",res);
					      	ElMessage({
							    message: proxy.$t('apply_common.upload_success'),
							    grouping: true,
							    type: 'success',
							})
					      	router.push({name:'collect_work',query:{type:data.type}})
					    }).catch(err => {
				            // console.error(err)
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.video_disabled = false;
				        })
				    }else{
				    	store.dispatch("user/PostCollect",param).then((res)=>{
					      	// console.log("PostCollect",res);
					      	ElMessage({
							    message: proxy.$t('apply_common.upload_success'),
							    grouping: true,
							    type: 'success',
							})

					      	router.push({name:'collect_work',query:{type:data.type}})
							
					    }).catch(err => {
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.video_disabled = false;
				        })
				    }
				    
			    } else {
			      console.log('error submit!')
			      data.video_disabled = false;
			      return false
			    }
			})
		}
        
        const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")
		  
		  if(tab.paneName == 'first'){
		  	data.type = 1;// 图片
		  }else if(tab.paneName == 'second'){
		  	data.type = 2;// 视频		  	
		  }
		  FolderList(data.type,-1,1);
		  // UserSubjectList(data.subtype);
		}

		const folderRef = ref()
		const folderForm = reactive({	
			title:'',	
  			description:'',
  			cover_type:1,
  			thumb: '',	
  			type: data.type,
		})

		const folder_rules = reactive({
			title: [
				{ required: true, message: proxy.$t('apply_common.enter_folder_title'), trigger: 'blur' },	
			],
			
		})

		const submitFolderForm = (formEl) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.folder_disabled = true;
			data.folderFormLoading = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    
				    folderForm.type = data.type;
				    console.log(folderForm,'addfolderForm')
				    store.dispatch('user/AddFolder',folderForm).then(res=>{
				    	console.log(res,"folder add");
				    	FolderList(data.type,-1);
				    	ElMessage({
						    message: proxy.$t('active.operate_success'),
						    type: 'success',
						})
						data.folderFormLoading = false;
						data.showFolder = false;
				    }).catch(err=>{
				    	ElMessage({
						    message: err.message,
						    type: 'error',
						})
						data.folder_disabled = false;
						data.folderFormLoading = false;
				    })
				    
			    } else {
			      console.log('error submit!')
			      data.folder_disabled = false;
			      data.folderFormLoading = false;
			      return false
			    }
			})
		}

        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
 
			}
		}

		const loadCollectInfo=()=>{
			if(id>0 && type==1){
				data.showImgUpload = false;
				data.showPicForm = true;
				store.dispatch('user/getCollectDetail',{id:id}).then(res=>{
					console.log(res,'getCollectDetail');
					ruleForm.tableData[0].title = res.title;
		    		ruleForm.tableData[0].description = res.description;
		    		ruleForm.tableData[0].keywords = res.keywords;//(res.keywords instanceof Array)?res.keywords.join(','):res.keywords;
		    		ruleForm.tableData[0].actid = res.actid;
		    		ruleForm.tableData[0].thumb = res.thumb;
		    		ruleForm.tableData[0].url = res.url;
		    		ruleForm.tableData[0].folder_id = res.folder_id;
		  			ruleForm.tableData[0].author = res.author;
		  			ruleForm.tableData[0].create_date = res.create_date;
		  			ruleForm.tableData[0].cate = res.cate;
				})
				
			}else if(id>0 && type==2){
				data.showVideoUpload = false;
				data.showVideoForm = true;
				store.dispatch('user/getCollectDetail',{id:id}).then(res=>{
					console.log(res,'getCollectDetail');
					ruleVideoForm.tableData[0].title = res.title;
		    		ruleVideoForm.tableData[0].description = res.description;
		    		ruleVideoForm.tableData[0].keywords = res.keywords;//(res.keywords instanceof Array)?res.keywords.join(','):res.keywords;
		    		ruleVideoForm.tableData[0].actid = res.actid;
		    		ruleVideoForm.tableData[0].thumb = res.thumb;
		    		ruleVideoForm.tableData[0].url = res.url;
		    		ruleVideoForm.tableData[0].folder_id = res.folder_id;
		  			ruleVideoForm.tableData[0].author = res.author;
		  			ruleVideoForm.tableData[0].create_date = res.create_date;
		  			ruleVideoForm.tableData[0].cate = res.cate;
				})
			}
		}

        onMounted(async()=>{
        	loadCollectInfo();
        	getActivityDetail(data.actid,data.type);
        	// getActivityList(data.search);
        	FolderList(1,-1);
        	FolderList(2,-1);
        	store.dispatch("common/fetchSiteInfo").then((res) => {
	            data.banners = res.activity_sliders  
	            data.protocol = res.protocal;
	            bus.emit('isLoading',false);  

        		// bus.emit('showFooter',true);
        	
	        }).catch(err => {
	            console.error(err)
	        })
	        // data.protocal = store.state.common.siteInfo['protocal'];
        	// fetchGrapher(data.keyword,data.catid,0,data.page);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })   

		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const handleSizeChange = (val) => {
		  console.log(`${val} items per page`)
		}
		const handleCurrentChange = (val) => {
		  console.log(`current page: ${val}`)
		  data.page = val;
		}
		const del = (type, index) => {
			if(type=='image'){
				// data.images.splice(index,1);
				// ruleUser.images.splice(index,1);
			}else if(type=='video'){
				// data.video.splice(index,1);
				// ruleUser.video.splice(index,1);
			}
		}

        const refData = toRefs(data);
	    return {
	        ...refData,
	        handleSizeChange,
	        handleCurrentChange,
	        viewBox,
	        scroll,
	        handleClick,
	        ruleRef,
			ruleForm,
			_rules,			
			submitForm,
			ruleVideoRef,
			ruleVideoForm,
			video_rules,
			submitVideoForm,
			getActivityList,
			picOneRef,
			picOneForm,
			videoOneRef,
			videoOneForm,
			del,
			loadCollectInfo,
			FolderList,
			folderRef,
			folderForm,
			folder_rules,
			submitFolderForm,
			getActivityDetail,
	    }
	},
	computed: {

	},
	watch(){

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},

	methods: {
		init() {

		},	
		addFolder() {
			this.showFolder = true;
		},
		getCategory(type,actid){
			// let actid =  this.picOneForm.actid;
			let info = {};
			this.actList.forEach((item,index)=>{
				if(actid == item.id){
					info = item;
					if(type=='image'){
						this.picCate = info.cates;
					}else if(type=='video'){
						this.videoCate = info.cates;
					}
				}
			})
			
			
		},
		closeFolder() {
			this.showFolder = false;
		},
		handleKeyword(type,index=false) {
			if(type=='image'){
				if(index!==false){
					let newKeywords = this.ruleForm.tableData[index].keywords.slice();
					this.ruleForm.tableData[index].pic_keyword && newKeywords.push(this.ruleForm.tableData[index].pic_keyword);
					this.ruleForm.tableData[index].keywords = newKeywords;
					this.ruleForm.tableData[index].pic_keyword = '';
				}else{
					this.pic_keyword && this.picOneForm.keywords.push(this.pic_keyword);
					this.pic_keyword = '';
				}
				
			}else if(type=='video'){
				if(index!==false){
					let newKeywords = this.ruleVideoForm.tableData[index].keywords.slice();
					this.ruleVideoForm.tableData[index].video_keyword && newKeywords.push(this.ruleVideoForm.tableData[index].video_keyword);
					this.ruleVideoForm.tableData[index].keywords = newKeywords;
					this.ruleVideoForm.tableData[index].video_keyword = '';
				}else{
					this.video_keyword && this.videoOneForm.keywords.push(this.video_keyword);
					this.video_keyword = '';
				}
			}
		},
		
		delTag(tagIndex,type,index=false) {
			if(type=='image'){
				
				if(index===false){
					console.log(index,"index1");
					this.picOneForm.keywords.splice(tagIndex,1);
				}else{
					console.log(index,"index2");
					console.log(this.ruleForm.tableData[index],"item");
					let aa = this.ruleForm.tableData[index].keywords.slice();
					aa.splice(tagIndex,1);
					console.log(aa,"aa");
					this.ruleForm.tableData[index].keywords = aa;
				}
					
			}else if(type=='video'){
				if(index===false){
					console.log(index,"index1");
					this.videoOneForm.keywords.splice(tagIndex,1);
				}else{
					console.log(index,"index2");
					console.log(this.ruleVideoForm.tableData[index],"item");
					let aa = this.ruleVideoForm.tableData[index].keywords.slice();
					aa.splice(tagIndex,1);
					// console.log(aa,"aa");
					this.ruleVideoForm.tableData[index].keywords = aa;
				}
			}
		},
		toProtocols(){
			this.showDialog = true;
		},
		close() {
			this.showDialog = false;
		},
		delItem(index){
			if(this.type==1){
        		this.ruleForm.tableData.splice(index,1);
        	}else if(this.type==2){           		
        		this.ruleVideoForm.tableData.splice(index,1);
        	}
		},
		addItem(){
			if(this.type==1){
				let obj = {
					title:'',			
		  			description: this.picOneForm.description,
		  			keywords: this.picOneForm.keywords,
		  			thumb: '',	
		  			actid: this.picOneForm.actid,
		  			url: '',
		  			folder_id: this.picOneForm.folder_id,
		  			author: this.picOneForm.author,
		  			create_date: this.picOneForm.create_date,
		  			cate: this.picOneForm.cate,
				};
        		this.ruleForm.tableData.push(obj);
        		console.log(this.ruleForm.tableData,"tabledata");
        	}else if(this.type==2){    
        	    let videoObj = {
					title:'',			
		  			description:this.videoOneForm.description,
		  			keywords: this.videoOneForm.keywords,
		  			actid: this.videoOneForm.actid,
		  			thumb: '',	
		  			url:'',
		  			folder_id: this.videoOneForm.folder_id,
		  			author: this.videoOneForm.author,
		  			create_date: this.videoOneForm.create_date,
		  			cate: this.videoOneForm.cate,
				};     		
        		this.ruleVideoForm.tableData.push(videoObj);
        	}
		},
		switchTag(item) {
			this.tag = item.id;
			if(item.id == 1){
				this.showList = true;
      			this.showRevelation = false;
			}else if(item.id == 2){
				this.showList = false;
      			this.showRevelation = true;
			}
		},
		async activeStyle(item) {		
			if(item.ratio_w == null || item.ratio_h == null) {
				
			    let size = await this.$utils.getImageSize(item.preview);
			    console.log(size,"size");
			    item.ratio_w = size.width;
			    item.ratio_h = size.height;

			}

			let object = {};
			object.width = item.ratio_w*180/item.ratio_h+'px';

			return object;
		},
		handleCameraDrop(e) {
		    e.preventDefault();
		    // let files = e.dataTransfer.files;
		    this.picFile = e.dataTransfer.files;
		    console.log(this.picFiles,"this.picFiles");

	    },
	    handleCameraDragOver(e) {
	      e.preventDefault();
	    },
	   
	    uploadCameraFile(event, type){
        	let that = this;
        	event.preventDefault();
        	if(type=='image'){
        		that.isPicLoading = true;
        	}else{        		
        		that.isVideoLoading = true;
        	}
            this.picFiles = event.currentTarget.files;
            console.log(this.picFiles,"this.picFiles");
       //      let has = false;
       //      for(let i=0;i<this.picFiles.length;i++){
       //      	if(this.picFiles[i].size > 50*1024*1024){
       //      		has = true;
       //      		break;
       //      	}
       //      }
       //      if(has){
       //      	ElMessage({
			    //     message: this.$t('size_exceed'),
			    //     type: "warning",
			    // });
       //      }else{
            if(this.picFiles.length > 0){
            	if(this.picFiles.length > 8){
            		if(type=='image'){
            			that.isPicLoading = false;
	            		ElMessage({
					        message: this.$t('upload_pic_eight'),
					        type: "warning",
					    });
	            	}else{
	            		that.isVideoLoading = false;
	            		ElMessage({
					        message: this.$t('upload_video_eight'),
					        type: "warning",
					    });
	            	}
            		
            	}else{
	            	if(type=='image'){	
	            		that.ruleForm.tableData.splice(0,1);
	            	}else{           		
	            		that.ruleVideoForm.tableData.splice(0,1);
	            	}
	            	
	            	
	            	for(let i=0;i<this.picFiles.length;i++){
	            		let fileInfo = {};
	            		fileInfo.title = this.picFiles[i].name;
	            		// fileInfo.description = '';
	            		// fileInfo.keywords = '';
	            		// fileInfo.actid = '';
	            		if(type=='image'){

	            			fileInfo.description = this.picOneForm.description;
	            			fileInfo.keywords = this.picOneForm.keywords;
	            			fileInfo.actid = this.picOneForm.actid;
	            			fileInfo.folder_id = this.picOneForm.folder_id;
				  			fileInfo.author = this.picOneForm.author;
				  			fileInfo.create_date = this.picOneForm.create_date;
				  			fileInfo.cate = this.picOneForm.cate;
		            	}else if(type=='video'){
		            		fileInfo.description = this.videoOneForm.description;
	            			fileInfo.keywords = this.videoOneForm.keywords;
	            			fileInfo.actid = this.videoOneForm.actid;
	            			fileInfo.folder_id = this.videoOneForm.folder_id;
				  			fileInfo.author = this.videoOneForm.author;
				  			fileInfo.create_date = this.videoOneForm.create_date;
				  			fileInfo.cate = this.videoOneForm.cate;
		            	}
	            		
	            		// console.log(this.picFiles[0],"this.picFiles-1");
	            		var formData = new FormData();
	            		formData.append("file", this.picFiles[i]);
		            	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
			            	// console.log("upload",res);
			            	// fileInfo.url = res.url;
			            	
			            	if(type=='image'){
			            		// if(res.similar.length > 0){
			            		// 	fileInfo.hasSimilar = true;
			            		// }else{
			            		// 	fileInfo.hasSimilar = false;
			            		// }
			            		fileInfo.thumb = res.url;
			            		that.ruleForm.tableData.push(fileInfo);
			            		// console.log(that.ruleForm.tableData,"filesInfo")
			            		that.showImgUpload = false;
			            		that.showPicForm = true;
			            		that.isPicLoading = false;
			            	}else if(type=='video'){
			            		that.getVideoFirstFrame(res.url).then(thumb=>{
			            			fileInfo.thumb = thumb.url;
			            			fileInfo.url = res.url;
				            		that.ruleVideoForm.tableData.push(fileInfo);
				            		// console.log(that.ruleVideoForm.tableData,"videoInfo")
				            		that.showVideoUpload = false;
				            		that.showVideoForm = true;
				            		that.isVideoLoading = false;
			            		});
			            		
			            		
			            	}

			            }).catch(err=>{
							ElMessage({
						        message: err.message,
						        type: "warning",
						    });
						})
		            }
		        }
	            // this.ruleForm.tableData = this.filesInfo;
            }
	        
        },
		uploadThumb(event){
	    	let that = this;
        	event.preventDefault();
        	this.folderThumbLoading = true;
            let file = event.currentTarget.files[0];
            // console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	console.log("upload-cover",res);
            	that.folderForm.thumb = res.url;
            	this.folderThumbLoading = false;
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			    this.folderThumbLoading = false;
			})
	    },
	  
	    uploadSingleFile(event,index,type){
	    	let that = this;
        	event.preventDefault();
        	if(type=='image'){
        		that.isImgSingleLoading = true;
        	}else if(type=='video'){        		
        		that.isVideoSingleLoading = true;
        	}
        	
            let file = event.currentTarget.files[0];
            // console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	// console.log("upload-1",res);
            	// fileInfo.url = res.url;
            	if(type=='image'){
            		// if(res.similar.length > 0){
            		// 	that.ruleForm.tableData[index].hasSimilar = true;
            		// }else{
            		// 	that.ruleForm.tableData[index].hasSimilar = false;
            		// }
            		that.ruleForm.tableData[index].title = file.name;
            		that.ruleForm.tableData[index].thumb = res.url;
            		that.isImgSingleLoading = false;
            	}else if(type=='video'){
            		that.getVideoFirstFrame(res.url).then(thumb=>{
            			that.ruleVideoForm.tableData[index].title = file.name;
	            		that.ruleVideoForm.tableData[index].thumb = thumb.url;
	            		that.ruleVideoForm.tableData[index].url = res.url;
	            		that.isVideoSingleLoading = false;
	            	})
            		
            	}
            	
            }).catch(err=>{
            	if(type=='image'){
            		that.isImgSingleLoading = false;
            	}else if(type=='video'){
            		that.isVideoSingleLoading = false;
            	}
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
	    },
	    getVideoFirstFrame(url, file='') {
	    	return new Promise((resolve, reject) => {
				const video = document.createElement('video') // 创建video对象
				video.src = url // url地址
				const canvas = document.createElement('canvas') // 创建 canvas 对象
				const ctx = canvas.getContext('2d') // 绘制2d
				video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
				video.currentTime = 1 // 第一秒帧
				// console.log(video,"video")
				video.oncanplay = (e) => {
					console.log('wh',e.target.videoWidth,e.target.videoHeight);
    				
				    canvas.width = e.target.videoWidth
				    canvas.height = e.target.videoHeight
				    // 利用canvas对象方法绘图
				    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

				    // 转换成base64形式
				    let base64_file = canvas.toDataURL('image/png');
				    // console.log(base64_file,"base64_file");
				    this.$store.dispatch('user/UploadBase64File',{base64_file: base64_file}).then(res=>{
				    	resolve({
				    		base64_file: base64_file,
				    		url: res.url,
				    	});
				    });
				    
				    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
				}
			})
		},
		searchAct() {
			this.getActivityList(this.search);
		},
		loadMore() {
			this.getActivityList(this.search,this.page);
		},
		loadMoreFolder() {
			this.FolderList(1,-1,this.folder_page);
		},
	    toDetail(id){
	      	// this.$router.push({ path:'/photo_grapher_detail', query:{ id: id } });
	    },
	    tagStyle(item) {
	    	if(this.tag == item.id){
	    		return ['active'];
	    	}
	    	return '';
	    },
	},
	beforeDestory() {

	},
	destoryed() {

	}
}